<template>
  <div class="home">
    <img src="@/assets/logo-white.png" alt="ENPRO Logo" />
    <h1>Welcome to TOIR</h1>
    <p>Select tab in the section with the right.</p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: var(--text-second);
}
.home img {
  margin-bottom: 50px;
}
</style>
